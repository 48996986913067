import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <body>
      <div id="contenu">
        <div id="slogan" className="effect1">
          <h1>Erreur 404: La page n'existe pas</h1>
        </div>

        <div id="infoComp">
          <div id="confirmation">
            <p>La page demandée n'existe pas.</p>
          </div>
        </div>
      </div>
    </body>
  </Layout>
);

export default NotFoundPage;
